<template>
    <div class="contact flex align-items-start justify-content-center z-w-100 z-h-100 pt-5">
        <div class="contact flex align-items-start justify-content-start z-w-80 flex-column mt-5 gap-5">
            <div class="flex align-items-center justify-content-center gap-8 ml-5">
                <div class="flex align-items-start justify-content-center gap-3">
                    <a class="phone" href="tel:+74951252719">+7 (495) 125-27-19</a>
                    <span class="phone-city ">Москва</span>
                </div>
                <div class="flex align-items-start justify-content-center gap-3">
                    <a class="phone" href="tel:+74012615586">+7 (4012) 61-55-86</a>
                    <span class="phone-city ">Калининград</span>
                </div>
                <div class="ml-8">
                    <a rel="nofollow" href="https://wa.me/74993720434" target="_blank">
                        <svg class="" width="30" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 418.135 418.135" xml:space="preserve">
						<g>
							<path style="fill:#7AD06D;" d="M198.929,0.242C88.5,5.5,1.356,97.466,1.691,208.02c0.102,33.672,8.231,65.454,22.571,93.536L2.245,408.429c-1.191,5.781,4.023,10.843,9.766,9.483l104.723-24.811c26.905,13.402,57.125,21.143,89.108,21.631c112.869,1.724,206.982-87.897,210.5-200.724C420.113,93.065,320.295-5.538,198.929,0.242z M323.886,322.197c-30.669,30.669-71.446,47.559-114.818,47.559c-25.396,0-49.71-5.698-72.269-16.935l-14.584-7.265l-64.206,15.212l13.515-65.607l-7.185-14.07c-11.711-22.935-17.649-47.736-17.649-73.713c0-43.373,16.89-84.149,47.559-114.819c30.395-30.395,71.837-47.56,114.822-47.56C252.443,45,293.218,61.89,323.887,92.558c30.669,30.669,47.559,71.445,47.56,114.817C371.446,250.361,354.281,291.803,323.886,322.197z"></path>
                            <path style="fill:#7AD06D;" d="M309.712,252.351l-40.169-11.534c-5.281-1.516-10.968-0.018-14.816,3.903l-9.823,10.008c-4.142,4.22-10.427,5.576-15.909,3.358c-19.002-7.69-58.974-43.23-69.182-61.007c-2.945-5.128-2.458-11.539,1.158-16.218l8.576-11.095c3.36-4.347,4.069-10.185,1.847-15.21l-16.9-38.223c-4.048-9.155-15.747-11.82-23.39-5.356c-11.211,9.482-24.513,23.891-26.13,39.854c-2.851,28.144,9.219,63.622,54.862,106.222c52.73,49.215,94.956,55.717,122.449,49.057c15.594-3.777,28.056-18.919,35.921-31.317C323.568,266.34,319.334,255.114,309.712,252.351z"></path>
					    </g>
                    </svg>
                    </a>
                </div>
            </div>
            <div class="flex align-items-start justify-content-center gap-5 flex-column  z-w-100">
                <div class="flex align-items-start justify-content-start z-w-50 gap-5 ml-5">
                    <span class="phone-city z-w-50 text-left">Почта:</span>
                    <a class="phone" href="mailto:mail@prozrenie.ru">mail@prozrenie.ru</a>
                </div>
                <div class="flex align-items-start justify-content-start z-w-50 gap-5 ml-5">
                    <span class="phone-city z-w-50 text-left">Контакты для СМИ и блогеров:</span>
                    <a class="phone" href="mailto:pr@prozrenie.ru">pr@prozrenie.ru</a>
                </div>
                <div class="flex align-items-start justify-content-start z-w-50 gap-5 ml-5">
                    <span class="phone-city z-w-50 text-left">Контакты HR:</span>
                    <a class="phone" href="mailto:hr@prozrenie.ru">hr@prozrenie.ru</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import {useRouter} from "vue-router";

export default defineComponent({
    name: 'Contacts',
    setup() {

        const router = useRouter();

        const remove = () => {
            router.replace({ name: 'Contacts'});
        }

        return {
            remove
        }
    }
});
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css?family=Lato:300,400|Poppins:300,400,800&display=swap');

.contact {
    background: #EFF3F8;
    color: #5F5B5C;
}

.phone {
    text-decoration: none;
    font-size: 1.3rem;
    font-family: Lato, sans-serif;
    color: #3f51b5;

    &-city {
        @extend .phone;
        //font-weight: bold;
        color: #5F5B5C;
    }
}

</style>